import React from "react";
import { Helmet } from "react-helmet";

export interface SEOProps {
	siteName: string;
	title: string;
	url: string;
	type: string;
	locale: string;
	description: string;
}

export function SEO(seo: SEOProps) {
	return (
		<Helmet>
			<title>{seo.title}</title>
			<meta charSet="utf-8" />
			<meta property="og:site_name" content={seo.siteName} />
			<meta property="og:url" content={seo.url} />
			<meta property="og:title" content={seo.title} />
			<meta property="og:type" content={seo.type} />
			<meta property="og:locale" content={seo.locale} />
			<meta property="og:description" content={seo.description} />
			<meta name="twitter:url" content={seo.url} />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content={seo.siteName} />
			<meta name="twitter:title" content={seo.title} />
			<meta name="twitter:description" content={seo.description} />
			<meta name="twitter:text:description" content={seo.description} />
		</Helmet>
	);
}

export default SEO;
