import React from "react";
import { Email, Visibility, VisibilityOff } from "@material-ui/icons";
import FormControlWidget from "../Widgets/FormControlWidget";
import { SizedBoxHeight } from "../Widgets/SizedBox";
import {
	Button,
	Container,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import UserConnexionModel from "../Models/UserConnexion";
import { signIn, signInError } from "../redux/actions/authAction";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import SEO from "../Components/Seo";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: theme.spacing(20),
		},
		title: {
			fontSize: 20.0,
			fontWeight: "bold",
			color: "grey",
		},
	}),
);
function LoginPage(props: any) {
	const classes = useStyles();
	const [user, setUser] = React.useState<UserConnexionModel>({
		password: "",
		email: "",
		showPassword: false,
	});
	// const { signIn } = props;
	const { uid } = props;

	if (uid) return <Redirect to="/" />;

	const handleClickShowPassword = () => {
		setUser({ ...user, showPassword: !user.showPassword });
	};

	const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const userResponse = await props.signIn(user);
		// console.log(userResponse);
	};

	const handleOnChangeInput =
		(props: keyof UserConnexionModel) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setUser({ ...user, [props]: event.target.value });
		};
	return (
		<>
			<SEO
				siteName={"fasoenchere"}
				title={`Connecter vous ou créer votre compte aujourd'hui`}
				url={"https://fasoenchere.com/login"}
				type={"website"}
				locale={"fr_FR"}
				description={`La société Smart chain International est une société de droit
					burkinabè. Elle fait la promotion de Unicorn Network qui est une
					entreprise communautaire mondiale`}
			/>
			<Container className={classes.root} maxWidth="xs">
				<Grid container alignContent="center" alignItems="center">
					<Typography className={classes.title}>
						Entrez vos coordonnées afin de vous connecter
					</Typography>
					<SizedBoxHeight height={24} />
					<form
						style={{
							width: "100%",
						}}
						onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
							handleSignIn(event)
						}>
						<FormControlWidget
							label="email"
							type="text"
							value={user.email}
							onChange={handleOnChangeInput("email")}
							id="userEmail"
							Icon={Email}
							htmlFor="email of User"
						/>
						<FormControlWidget
							label="Password"
							type={user.showPassword ? "text" : "password"}
							value={user.password}
							onChange={handleOnChangeInput("password")}
							id="userPassword"
							Icon={user.showPassword ? VisibilityOff : Visibility}
							htmlFor="password of User"
							onCLickIcon={handleClickShowPassword}
						/>
						<Link to="/forgot_password">
							<Typography color="secondary" variant="subtitle1">
								Mot de pass oublié ?
							</Typography>
						</Link>
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							style={{
								width: "50%",
								marginTop: "20px",
							}}>
							Soumettre
						</Button>
					</form>
				</Grid>
			</Container>
		</>
	);
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		signIn: (creds: UserConnexionModel) => dispatch(signIn(creds)),
	};
};

const mapStateToProps = (state: any) => {
	// console.log(state);
	const uid = state.firebase.auth.uid;
	return {
		uid,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
