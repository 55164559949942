import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  CircularProgress,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { CarouselComp } from "./CarouselComp/CarouselComp";
import firebase from "../firebaseConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageCarousel: {
      width: "100%",
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "300px",
      [theme.breakpoints.up("sm")]: {
        imageCarousel: {
          height: "200px",
        },
      },
    },
    imageBanner: {
      width: "100%",
      objectFit: "fill",
    },
  })
);

function BannerComp() {
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    getFromFirebase();
  }, []);
  const getFromFirebase = () => {
    //1.
    let storageRef = firebase.storage().ref("/imagesBannieres");
    //2.
    storageRef
      .listAll()
      .then(function (res: any) {
        //3.
        res.items.forEach((imageRef: any) => {
          imageRef.getDownloadURL().then((url: any) => {
            //4.
            setImages((allImages: any) => [...allImages, url]);
          });
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const classes = useStyles();
  const imagesSlides = images.map((image: any): any => {
    return (
      <img src={image} alt="" className={classes.imageBanner} height="100%" />
    );
  });
  const carousel =
    imagesSlides && images.length > 3 ? (
      <Container
        maxWidth="xl"
        className={classes.imageCarousel}
        style={{ marginTop: 200 }}
      >
        <CarouselComp slides={imagesSlides} autoplay={false} />
      </Container>
    ) : (
      <Container>
        <CircularProgress />
      </Container>
    );
  return carousel;
}

export default BannerComp;
