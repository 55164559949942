import authReducer from "./authReducers";
import productReducer from "./productReducers";
import { combineReducers } from "redux";
import { firestoreReducer as firestore } from "redux-firestore";
import { firebaseReducer as firebase } from "react-redux-firebase";

const rootReducer = combineReducers({
  auth: authReducer,
  product: productReducer,
  firebase,
  firestore,
});

export default rootReducer;
