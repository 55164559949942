import { toast } from "react-toastify";
const initState = {};
const authReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "SIGN_UP":
      return state;
    case "SIGN_UP_ERR":
      return state;
    case "SIGN_IN":
      return state;
    case "SIGN_IN_ERR":
      toast("error de connexion");
      return state;
    case "SIGN_OUT":
      toast.info(
        "Déconnecter avec success",
        {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return state;
    case "RESET_PASSWORD":
      return state;
    default:
      return state;
  }
};

export default authReducer;
