import { Typography } from "@material-ui/core";

function TermeOfUse() {
  return (
    <div>
      <Typography
        variant="h4"
        color="textPrimary"
        style={{ fontSize: "24px", fontWeight: "bold" }}
      >
        Avertissement:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Cette plateforme de vente aux enchères dénommée « FASO ENCHERES » est la
        propriété de Smart Chain International Burkina partenaire exclusif de
        Unicorn Network, et ne peut ni ne doit être l’objet de manipulation ou
        de piratage par qui que ce soit. Tout contrevenant se verra poursuivi
        devant les juridictions compétentes. FASO ENCHERES vise à promouvoir la
        monnaie YEM (Your Evreday Money) à travers la vente des produits de
        qualités à 100% Yem. La vente aux enchères est une vente ouverte au
        public, au cours de laquelle le bien est attribué au plus offrant. Notre
        plateforme FASO ENCHERES est ouverte au monde entier. Ainsi, tout le
        monde peut participer à nos offres. Cependant, vous êtes toujours
        personnellement responsable de vos propres actions; rien de ce qui est
        contenu dans cette plateforme ne doit être considéré comme un conseil ou
        une obligation pour quelque raison que ce soit. Faites preuve de
        diligence raisonnable et discutez avec vos propres experts avant de
        prendre des décisions, par exemple en matière de dépenses en yem et de
        fiscalité, pour n'en nommer que quelques-unes. Les articles seront
        exposés sur le sites FASO ENCHERES pendant au moins deux semaines avant
        le début des enchères qui dureront également deux semaines. Les futurs
        acheteurs ont tout le temps de poser toutes les questions qu’ils
        souhaitent sur un ou plusieurs biens tels que l'année de fabrication, le
        nom ou marque et l’origine ou les matériaux qui composent le bien, etc.
        Le gagnant de la vente a l’obligation de verser le montant de yem qu’il
        a proposé sous peine de voir son compte pernum bloqué et des poursuites
        judiciaires à son encontre. Le transfert des biens (article - yem) se
        fera devant un notaire. Pour les gagnants non nationaux, ils devront
        supporter les frais de douanes et les frais connexes. Les documents à
        fournir seront définis au moment opportun par le notaire pour finaliser
        les transactions.
      </Typography>
      <Typography
        variant="h4"
        color="textPrimary"
        style={{ fontSize: "24px", fontWeight: "bolder" }}
      >
        Declaration de confidentialité : 
      </Typography>
      <Typography color="textSecondary">
      Sur ce site toutes les informations concernant les mails, numéro de téléphone, noms et prénoms, ect ne peuvent pas faire l'objet de partage. Elles restent secrètes et confidentielles.
      </Typography>
      <Typography
        variant="h4"
        color="textPrimary"
        style={{ fontSize: "24px", fontWeight: "bolder" }}
      >
        Comment ça marche ?
      </Typography>
      <Typography color="textSecondary">
        Allez sur le site Faso enchères, inscrivez-vous gratuitement et placez
        votre un ordre d’achat (mettez la quantité de yem dont vous voulez
        débourser). Votre ordre d’achat sera exécuté automatiquement et révélé
        aux publics.
      </Typography>
    </div>
  );
}

export default TermeOfUse;
