import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import InputModel from "../Models/InputModel";
import { SizedBoxHeight } from "./SizedBox";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
}));

function FormControlWidget(props: InputModel) {
  const { label, htmlFor, id, type, value, onChange, Icon, onCLickIcon, placeholder } =
    props;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} xl={12} md={12}>
      <FormControl className={classes.root}>
        <InputLabel htmlFor={htmlFor}>{label}</InputLabel>
        <Input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label={htmlFor} onClick={onCLickIcon}>
                <Icon />
              </IconButton>
            </InputAdornment>
          }
        />
        <SizedBoxHeight height={12} />
      </FormControl>
    </Grid>
  );
}

export default FormControlWidget;
