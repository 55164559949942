import {
  isValidPhoneNumber,
  formatPhoneNumber,
} from "react-phone-number-input";
import UserModel from "../Models/UserModel";

export class RegexValidationUtils {
  static validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
  static validPhoneNumber = (phoneNumber: string) =>
    isValidPhoneNumber(phoneNumber);
  static formatPhoneNumber(phoneNumber: string) {
    return formatPhoneNumber(phoneNumber);
  }
  static validEmail = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
  );
  static validName = new RegExp(
    "^[\\w'\\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$"
  );
  static validNumber = new RegExp("^[0-9]*$");
  static validPerNumNumber = new RegExp("^[0-9]+$");

  static validateInput(
    user: UserModel,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    let error: Partial<UserModel> = {};
    if (event.target.name === user.password)
      if (!this.validPassword.test(user.password))
        return (error = {
          password:
            "veuillez entrer un mote de pass valid ex: (6 chiffres minimum)",
        });
    if (!this.validEmail.test(user.email))
      return (error = {
        email: "veuillez entrer une adress mail valid. ex: enchere@gmail.com",
      });
    if (!this.validPhoneNumber(user.phoneNumber))
      return (error = {
        phoneNumber:
          "veuillez entrer un numéro de téléphone valid ex: +22651979827",
      });
    if (!this.validName.test(user.firstName))
      return (error = { firstName: "veuillez entrer un prénom valid" });
    if (!this.validName.test(user.lastName))
      return (error = { lastName: "veuillez entrer un nom valid" });

    return error;
  }

  static checkIfError(
    user: UserModel,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const error: Partial<UserModel> = this.validateInput(user, event);
    if (error) return true;
    else return false;
  }
}
