import React from "react";
import {
	Container,
	Typography,
	Theme,
	createStyles,
	Grid,
	Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SEO from "../Components/Seo";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: "65px",
		},
		container: {
			marginTop: 10,
		},
		containerItem: {
			height: 400,
			width: "100%",
		},
		clickHere: {
			width: "40%",
			height: 50,
			margin: "auto",
		},
		joinUs: {},
	}),
);
function AboutUsPage() {
	const classes = useStyles();
	return (
		<>
			<SEO
				siteName={"fasoenchere"}
				title={`Faso enchere Nous connaitre d'avantage`}
				url={"https://fasoenchere.com/about_us"}
				type={"website"}
				locale={"fr_FR"}
				description={`La société Smart chain International est une société de droit
					burkinabè. Elle fait la promotion de Unicorn Network qui est une
					entreprise communautaire mondiale`}
			/>
			<Container maxWidth="md" className={classes.root}>
				<Typography
					variant="h5"
					color="secondary"
					style={{ fontWeight: "bold" }}>
					PRÉSENTATION DE SMARTCHAIN INTERNATIONAL ET DE UNICORN NETWORK
				</Typography>
				<Typography variant="h6" style={{ fontSize: 16, fontWeight: "bolder" }}>
					La société Smart chain International est une société de droit
					burkinabè. Elle fait la promotion de Unicorn Network qui est une
					entreprise communautaire mondiale composée de personnes physiques et
					morales dont le siège social est basé à Las Vegas dans l’Etat de
					Nevada aux USA. L’objectif principal de Unicorn Network est la
					promotion d’un environ sain sur internet, la promotion des entreprises
					par la sécurisation des données et la transparence dans l’utilisation
					de la monnaie numérique Yem et de ses sous devises dans le monde. Au
					travers de cette plateforme de vente aux enchères nous voulons
					permettre aux membres de la communauté de tiré déjà profit de leur
					argent Yem.
				</Typography>
				<Grid container className={classes.container}>
					<Typography
						color="textPrimary"
						variant="h3"
						align="center"
						style={{
							fontSize: 22,
							fontWeight: "bold",
							margin: "auto",
							fontStyle: "oblique",
							textDecoration: "underline",
							color: "green",
						}}>
						Une Vidéo qui parle bien de Nous:
					</Typography>
					<div style={{ height: 50 }}></div>
					<Grid item className={classes.containerItem}>
						<iframe
							title="video d'explication"
							src="https://safe.zone/SafeZone_explainer.mp4"
							height="97%"
							width="75%"></iframe>
					</Grid>
					<Typography
						variant="h5"
						style={{ fontSize: 16, fontWeight: "bolder" }}>
						Cette dynamique nous a également conduits à l’Union européenne, à la
						cour de Justice européenne. Tout ce processsus visait à assurer les
						autorités de la fiabilité du YEM. Et cela a porté ses fruits car à
						titre d’exemple, aujourd’hui, notre monnaie est acceptée en
						Allemagne comme substitut de l’euro. En plus, les banques centrales
						sont déjà impliquées dans la devise YEM
					</Typography>
					<Typography
						variant="h4"
						className={classes.joinUs}
						color="textPrimary"
						align="center"
						style={{ margin: "auto" }}>
						Rejoignez Nous en vous inscrivant dès aujourd'hui.
					</Typography>
					<div style={{ height: 50 }}></div>
					<Button
						fullWidth
						variant="contained"
						className={classes.clickHere}
						color="secondary">
						<a
							href="https://safe.zone/1000108858"
							rel="noopener noreferrer"
							target="_blank"
							style={{ color: "#fff" }}>
							Cliker pour vous inscrire à safezone.
						</a>
					</Button>
					<div style={{ height: 100 }}></div>
				</Grid>
			</Container>
		</>
	);
}

export default AboutUsPage;
