import React, { ReactElement } from "react";
import { SizedBoxHeight } from "./SizedBox";
import {
  Typography,
  Grid,
  Card,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { CarouselComp } from "../Components/CarouselComp/CarouselComp";
import { ProductModel } from "../Models/ProductModel";
import { Price } from "../Models/PriceModel";
import ModalForm from "./ModalForm";
import SimpleModal from "./SimpleModal";
import ArticleCaracteristiqueModal from "./ArticleCaracteristiqueModal";
import PersonList from "./PersonList";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "10px",
    },
    productTitle: {
      fontSize: "40px",
      fontWeight: "bold",
      color: "black",
      textAlign: "start",
    },
    productCard: {
      marginBottom: "16px",
      padding: "16px",
    },
    productInformation: {
      marginTop: "60px",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      alignContent: "center",
      textJustify: "initial",
    },
    price: {
      fontSize: "32px",
      fontWeight: "bold",
    },
    productSubTitle: {
      textAlign: "start",
    },
    priceDevice: {
      fontSize: "16px",
      fontStyle: "italic",
      fontWeight: "normal",
    },
    addPrice: {
      width: "100%",
      display: "flex",
    },
    userInfo: {
      color: "#f50057",
      fontWeight: "bold",
      fontSize: "16px",
    },
  })
);
const ProductItem = (product: ProductModel): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openCaracteristique, setOpenCaracteristique] = React.useState(false);
  const [openPersons, setOpenPersons] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenCaratérique = () => {
    setOpenCaracteristique(true);
  };
  const handleCloseCaratérique = () => {
    setOpenCaracteristique(false);
  };

  const handleOpenPerson = () => {
    setOpenPersons(true);
  };

  const handleClosePerson = () => {
    setOpenPersons(false);
  };

  const images = product.images.map((image) => {
    return <img src={image} alt="product" />;
  });
  const getTheGreatestPrice = (prices: Price[]) => {
    let largestValue: number = 0;
    let index = 0;
    prices.forEach((price: Price, indexLoop: number) => {
      if (price.price > largestValue) {
        largestValue = price.price;
        index = indexLoop;
      }
    });
    return prices[index];
  };
  return (
    <Card className={classes.productCard}>
      <Grid container alignContent="center" justifyContent="center">
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <CarouselComp autoplay={false} slides={images} interval={1000} />
        </Grid>
        <Grid
          className={classes.productInformation}
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <Typography variant="h3" className={classes.productTitle}>
            {product.prices.length} personnes ont enchéri
          </Typography>
          <Typography variant="h1" className={classes.productTitle}>
            {product.title}
          </Typography>
          <Typography variant="h1" className={classes.productTitle}>
            {product.singlePrice}
          </Typography>
          <Typography className={classes.productSubTitle}>
            {product.subTitle}
          </Typography>
          <SizedBoxHeight height={2} />
          <Typography>Dernier Enchèrier:</Typography>
          <Typography>
            Pays de l'enchère:{" "}
            <span className={classes.userInfo}>{`${
              getTheGreatestPrice(product.prices)?.country.label ||
              "Burkina Faso"
            }`}</span>
          </Typography>
          <Typography>
            PerNum:{" "}
            <span className={classes.userInfo}>
              {getTheGreatestPrice(product.prices).perNum}
            </span>
          </Typography>
          <Typography className={classes.price}>
            {getTheGreatestPrice(product.prices).price}{" "}
            <span className={classes.priceDevice}>YEM</span>
          </Typography>
          <SizedBoxHeight height={20} />
          <div className={classes.addPrice}>
            {product.active ? (
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleOpen()}
                color="secondary"
              >
                Clicker pour monter l'enchère
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                color="inherit"
                disabled={true}
              >
                L'enchère de ce produit est dépassé.
              </Button>
            )}
          </div>
          <br />
          <div className={classes.addPrice}>
            {product.caracteristiques && product.caracteristiques.length > 10 && (
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleOpenCaratérique()}
                color="primary"
              >
                Voir les charactéristiques du produit
              </Button>
            )}
          </div>
          <br />
          <div className={classes.addPrice}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleOpenPerson()}
              color="default"
            >
              Voir la liste des enchéries
            </Button>
          </div>
          <br />
          <ModalForm
            open={open}
            handleClose={handleClose}
            price={getTheGreatestPrice(product.prices)}
            product={product}
          />
          <SimpleModal
            handleCloseModal={handleCloseCaratérique}
            body={
              <ArticleCaracteristiqueModal
                handleClose={handleCloseCaratérique}
                caracteristiques={product.caracteristiques}
              />
            }
            open={openCaracteristique}
          ></SimpleModal>

          <SimpleModal
            handleCloseModal={handleOpenPerson}
            body={
              <PersonList product={product} handleClose={handleClosePerson} />
            }
            open={openPersons}
          ></SimpleModal>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductItem;
