import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/RegisterPage";
import NavbarComp from "./Components/NavbarComp";
import HomePage from "./Pages/HomePage";
import { CssBaseline } from "@material-ui/core";
import AboutUsPage from "./Pages/AboutUsPage";
import WaitingPage from "./Pages/WaitingPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";

function App() {
  return (
    <div className="App">
      <Router>
        <NavbarComp />
        <Switch>
          <Route path="/login" exact>
            <LoginPage />
          </Route>
          <Route path="/register" exact>
            <RegisterPage />
          </Route>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/about_us" exact>
            <AboutUsPage />
          </Route>
          <Route path="/wating_page" exact>
            <WaitingPage />
          </Route>
          <Route path="/forgot_password" exact>
            <ResetPasswordPage />
          </Route>
        </Switch>
      </Router>
      <CssBaseline />
    </div>
  );
}

export default App;
