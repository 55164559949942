import { Container } from "@material-ui/core";

function SizedBoxHeight(props: { height: number }) {
  return (
    <Container>
      <div style={{ paddingBottom: `${props.height}px` }}></div>
    </Container>
  );
}
function SizedBoxWidth(props: { width: number }) {
  return (
    <Container>
      <div style={{ paddingLeft: `${props.width}px` }}></div>
    </Container>
  );
}

export { SizedBoxHeight, SizedBoxWidth };
