import { Box, Container } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import BannerComp from "../Components/BannerComp";
import ProductPage from "./ProductPage";
import { SizedBoxHeight } from "../Widgets/SizedBox";
import SEO from "../Components/Seo";

function HomePage(props: any) {
	const { uid, emailVerified } = props;
	if (!uid) return <Redirect to="/login" />;
	if (emailVerified === false) return <Redirect to="/wating_page" />;
	return (
		<>
			<SEO
				siteName={"fasoenchere"}
				title={`Nous connaitre d'avantage`}
				url={"https://fasoenchere.com/"}
				type={"website"}
				locale={"fr_FR"}
				description={`La société Smart chain International est une société de droit
					burkinabè. Elle fait la promotion de Unicorn Network qui est une
					entreprise communautaire mondiale`}
			/>
			<Container>
				<BannerComp />
				<SizedBoxHeight height={10} />
				<Box style={{ marginTop: "570px" }}>
					<ProductPage />
				</Box>
			</Container>
		</>
	);
}

const mapStateToProps = (state: any) => {
	const uid = state.firebase.auth.uid;
	const emailVerified = state.firebase.auth.emailVerified;
	return {
		uid,
		emailVerified,
	};
};

export default connect(mapStateToProps)(HomePage);
