import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import ProductItem from "../Widgets/ProductItem";
import { ProductModel } from "../Models/ProductModel";

function ProductPage(props: any) {
	const [products, setProducts] = useState<any>([]);

	useEffect(() => {
		setProducts(props.products);
		// console.log(props.products);
	}, [props.products]);

	// console.log(
	// 	products && products.length > 0
	// 		? products?.sort(function (x, y) {
	// 				// true values first
	// 				return x.active === y.active ? 0 : x.active ? -1 : 1;
	// 				// false values first
	// 				// return (x === y)? 0 : x? 1 : -1;
	// 		  })
	// 		: [],
	// );

	const productsComp =
		products && products.length > 0 ? (
			[...products]
				.sort(function (a, b) {
					return b.active - a.active;
				})
				.map((product: ProductModel, index: number) => {
					return <ProductItem {...product} key={index} />;
				})
		) : (
			<div>
				<CircularProgress />
			</div>
		);
	return <div>{productsComp}</div>;
}

const mapStateToProps = (state: any) => {
	const products = state.firestore.ordered.products;
	return {
		products: products,
		uid: state.firebase.auth.uid,
	};
};

export default compose<any>(
	connect(mapStateToProps),
	firestoreConnect(() => [
		{
			collection: "products",
		},
	]),
)(ProductPage);
