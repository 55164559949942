import React from "react";
import { Email } from "@material-ui/icons";
import FormControlWidget from "../Widgets/FormControlWidget";
import { SizedBoxHeight } from "../Widgets/SizedBox";
import {
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import UserConnexionModel from "../Models/UserConnexion";
import { resetPassword } from "../redux/actions/authAction";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(20),
    },
    title: {
      fontSize: 20.0,
      fontWeight: "bold",
      color: "grey",
    },
  })
);

function ResetPasswordPage(props) {
  const classes = useStyles();
  const [user, setUser] = React.useState<UserConnexionModel>({
    password: "",
    email: "",
    showPassword: false,
  });
  // const { signIn } = props;
  const { uid } = props;
  // Let's show an error if the creds are wrong

  if (uid) return <Redirect to="/" />;

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await props.resetPassword(user.email);
    setUser({
      ...user,
      email: "",
    });
  };

  const handleOnChangeInput =
    (props: keyof UserConnexionModel) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUser({ ...user, [props]: event.target.value });
    };

  return (
    <Container className={classes.root} maxWidth="xs">
      <Grid container alignContent="center" alignItems="center">
        <Typography className={classes.title}>
          Veuillez inserer votre mail pour continuer. une fois terminer veuillez
          consulter votre boite mail pour continuer le processus de
          rénitialisation
        </Typography>
        <SizedBoxHeight height={24} />
        <form
          style={{
            width: "100%",
          }}
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            handleSignIn(event)
          }
        >
          <FormControlWidget
            label="email"
            type="text"
            value={user.email}
            onChange={handleOnChangeInput("email")}
            id="userEmail"
            Icon={Email}
            htmlFor="email of User"
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            style={{
              width: "50%",
              marginTop: "20px",
            }}
          >
            Soumettre
          </Button>
        </form>
      </Grid>
    </Container>
  );
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    resetPassword: (email: string) => dispatch(resetPassword(email)),
  };
};

const mapStateToProps = (state: any) => {
  const uid = state.firebase.auth.uid;
  // console.log(state.firebase.auth);
  return {
    uid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
