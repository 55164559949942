import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  OutlinedInput,
  Button,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Price } from "../Models/PriceModel";
import { addNewPrice } from "../redux/actions/productAction";
import { RegexValidationUtils } from "../utils/regexValidationUtils";
import SimpleModal from "./SimpleModal";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperBodyModal: {
    width: 300,
    // [theme.breakpoints.down('m')]: {
    //   width: 100
    // },
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    marginTop: "100px",
  },
  modalPop: {
    backgroundColor: "white",
    height: "fit-content",
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 600,
      margin: 'auto'
    },
    borderRadius: "24px",
    padding: theme.spacing(3, 5, 3, 5),
  },
  fade: {
    flex: 0.8,
  },
  heightBox: {
    height: 30,
  },
}));
function ModalForm(props: any) {
  const { uid, open, handleClose, price, product, addNewPrice } = props;
  const [error, setError] = useState<string>(
    "Votre prix doit être suprérieur à celui de votre prédécesseur"
  );
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenSimpleModal = () => {
    setOpenModal(true);
  };

  const handleCloseSimpleModal = () => {
    setOpenModal(false);
  };

  const classes = useStyles();
  const [newPrice, setNewPrice] = useState(price);
  useEffect(() => {
    setNewPrice(price);
    return () => {
      setError("Votre prix doit être suprérieur à celui de votre prédécesseur");
      setNewPrice(price);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!RegexValidationUtils.validNumber.test(value)) {
      setError("Le type d'entrée doit etre un chiffre");
    } else if (price.price >= value) {
      setError("Votre prix doit être suprérieur à celui de votre prédécesseur");
    } else setError("");
    setNewPrice(value);
  };

  const bodyConfirmation = (
    <div className={classes.paperBodyModal}>
      <Typography id="simple-modal-title" variant="h3" color="primary">
        Attention
      </Typography>
      <Typography
        id="simple-modal-description"
        variant="subtitle1"
        color="secondary"
      >
        Le gagant est obligé de payer le montant de YEM enchéri.
      </Typography>
      <Button
        style={{ margin: "auto", height: "50px" }}
        color="secondary"
        variant="outlined"
        onClick={async () => {
          if (!RegexValidationUtils.validNumber.test(newPrice)) {            
            toast.success(
              "Le type d'entrée doit etre un chiffre",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else if (price.price >= newPrice) {
            toast.success(
              "Votre prix doit être suprérieur à celui de votre prédécesseur",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else
          await addNewPrice(uid, product.id, product.prices, newPrice);
          handleCloseSimpleModal();
          handleClose();
        }}
      >
        Valider
      </Button>
      <Button
        style={{ margin: "auto", height: "50px" }}
        color="secondary"
        // variant="outlined"
        onClick={handleCloseSimpleModal}
      >
        Annuler
      </Button>
    </div>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <div className={classes.modalPop}>
              <Typography variant="h4" color="primary">
                Mis à jour du prix
              </Typography>
              <Typography variant="body2" color="secondary">
                <span style={{ height: 16.0, fontWeight: "bold" }}>NB:</span> ⚠️
                Votre action est irreversible une fois qu'elle est enregistrée.
                Par conséquent vous devrez disposer la quantité de YEM dans
                votre compte PERNUM vérifié.
              </Typography>
              <div className={classes.heightBox}></div>
              <OutlinedInput
                placeholder="Votre prix"
                type="text"
                value={newPrice.price}
                onChange={handleChange}
              />
              {/* <div className={classes.heightBox}></div> */}
              <Typography variant="body2" color="secondary">
                Vous êtes sur le point de soumêttre un nouveau prix au produit
              </Typography>
              <Typography color="textSecondary">{error}</Typography>
              <div className={classes.heightBox}></div>
              {error.length === 0  && (
                <Button
                  style={{ margin: "auto", height: "50px" }}
                  centerRipple
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    handleOpenSimpleModal();
                  }}
                >
                  Soumettre
                </Button>
              )}
              {/* // let's add a cancel button */}
              <Button
              style={{ margin: "10px 10px", height: "50px" }}
              variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={classes.heightBox}
              >
                Annuler
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <SimpleModal
        handleCloseModal={handleCloseSimpleModal}
        body={bodyConfirmation}
        open={openModal}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  const products = state.firestore.ordered.products;
  return {
    products: products,
    uid: state.firebase.auth.uid,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNewPrice: (
      userId: string,
      productId: string,
      prices: Price[],
      newPrice: string
    ) => dispatch(addNewPrice(userId, productId, prices, newPrice)),
  };
};

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => [
    {
      collection: "products",
    },
  ])
)(ModalForm);
