import { toast } from "react-toastify";
const initState = [];
const productReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "GET_PRODUCTS":
      return state;
    case "ADD_NEW_PRICE":
      toast("Your price is added successfully");
      return state;
    case "ADD_NEW_PRICE_ERR":
      toast("Error please try again");
      return state;
    default:
      break;
  }
  return state;
};

export default productReducer;
