// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyACj26Jb9I6HYcHovm2L6h6vwGf54_IHoI",
  authDomain: "ecommerce-f9df5.firebaseapp.com",
  databaseURL: "https://ecommerce-f9df5.firebaseio.com",
  projectId: "ecommerce-f9df5",
  storageBucket: "ecommerce-f9df5.appspot.com",
  messagingSenderId: "294092966359",
  appId: "1:294092966359:web:4ab155c140d6d73e1b2392",
  measurementId: "G-Y48MHMG6JZ",
};
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.auth();
firebase.storage();
export default firebase;
