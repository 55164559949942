import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import classes from "*.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '400px',
      margin: 'auto',
      padding: '20px',
      height: '600px',
      overflowY: 'scroll',
      background: 'white',
    },
  })
);

function SimpleModal(props: any) {
  const { handleCloseModal, body, open, handleModal } = props;
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default SimpleModal;
