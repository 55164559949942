import React from "react";
import { Theme, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core/styles";
import "./articleCaracteristiqueModal.css";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "400px",
      margin: "auto",
      padding: "20px",
      height: "600px",
      overflowY: "scroll",
      background: "white",
    },
    typographySubTitle: {
      fontWeight: "bold",
      fontSize: 18,
      color: "black",
    },
    title: {
      fontWeight: "bold",
      fontSize: 20,
      color: "red",
    },
    span: {
      fontSize: "12px",
      fontWeight: "bolder",
      fontStyle: "italic",
    },
    btn: {
      width: "200px",
    },
  })
);

function ArticleCaracteristiqueModal({ handleClose, caracteristiques }) {
  const classes = useStyles();
  return (
    <div className="root">
      {ReactHtmlParser(caracteristiques)}
      <div>
        <br />
        <Button
          variant="contained"
          onClick={handleClose}
          className={classes.btn}
          color="primary"
        >
          Fermer
        </Button>
      </div>
    </div>
  );
}

export default ArticleCaracteristiqueModal;
