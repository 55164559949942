import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 'auto',
    marginTop: '100px',
    width: '70%',
    height: '500px',
    overflowY: 'scroll',

  },
  olist: {
    overflowY: 'scroll',
  },
  table: {
    width: '100%',
  },
  tdAndTh: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px'
  },
  btn: {
    width: '200px',
    margin: 'auto'
  }
}));

function PersonList({product, handleClose,}) {

  const classes = useStyles();
  
  const persons = product.prices.map((productPrice) => {
    return (
        <tr>
        <td className={classes.tdAndTh}>
        <Typography>{productPrice.perNum}</Typography>
        </td>
        <td className={classes.tdAndTh}>
        <Typography>{productPrice.price}</Typography>
        </td>
        </tr>
)

  })
  return (
    <div className={classes.paper}>
      <table className={classes.table}>
    <tr>
      <th className={classes.tdAndTh}>
        Pernum
      </th>
      <th className={classes.tdAndTh}>
        YEM
      </th>
        </tr>
        { product && product.prices ? persons : <CircularProgress />}
        </table>
        <br />
      <Button variant='contained' onClick={handleClose} className={classes.btn} color='primary'>
        Fermer
      </Button>
    </div>
  )
}

export default PersonList
