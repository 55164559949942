import {
	Button,
	Checkbox,
	Container,
	createStyles,
	FormControlLabel,
	Grid,
	makeStyles,
	Modal,
	Theme,
	Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import UserModel from "../Models/UserModel";
import { Email, Lock, PermIdentity, Person, Phone } from "@material-ui/icons";
import FormControlWidget from "../Widgets/FormControlWidget";
import { SizedBoxHeight } from "../Widgets/SizedBox";
import { RegexValidationUtils } from "../utils/regexValidationUtils";
import { signUp } from "../redux/actions/authAction";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import TermeOfUse from "../Widgets/TermeOfUse";
import ReactFlagsSelect from "react-flags-select";
import SEO from "../Components/Seo";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: theme.spacing(15),
		},
		title: {
			fontSize: 20.0,
			fontWeight: "bold",
			color: "grey",
		},
		termeOfUse: {
			cursor: "pointer",
			":hover": {
				color: "orange",
			},
		},
		error: {
			color: "red",
			fontWeight: "bold",
			textAlign: "start",
		},
	}),
);

function RegisterPage(props: any) {
	const classes = useStyles();
	const [terme, setTerme] = useState(false);
	const [termeOfUse, setTermeOfUse] = useState(false);
	const { signOut, uid } = props;

	const [selected, setSelected] = useState("");
	const searchable = Boolean(true);

	const [user, setUser] = React.useState<UserModel>({
		country: "",
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
		perNum: "",
		phoneNumber: "",
		showPassword: false,
	});
	const [error, setError] = useState({
		firstName: "",
		lastName: "",
		email: "",
		perNum: "",
		country: "",
		phoneNumber: "",
		password: "",
		confirmPassword: "",
	});
	const [checkIfValidate, setCheckValidate] = useState({
		firstName: false,
		lastName: false,
		email: false,
		perNum: false,
		phoneNumber: false,
		country: false,
		password: false,
		confirmPassword: false,
		checkTermeOfUse: false,
	});
	if (uid) return <Redirect to="/" />;

	const handleCheckBoxChange: any = () => {
		setTerme(!terme);
		if (terme === true)
			setCheckValidate({ ...checkIfValidate, checkTermeOfUse: false });
		else setCheckValidate({ ...checkIfValidate, checkTermeOfUse: true });
		checkIfEveryThingIsOk();
	};

	const changeHandlerCountry = (value: any) => {
		setUser({
			...user,
			country: value,
		});
		if (value) {
			setCheckValidate({ ...checkIfValidate, country: true });
		} else {
			setError({
				...error,
				country: "Vous devrez insérer votre pays de résidence",
			});
			setCheckValidate({ ...checkIfValidate, country: false });
		}
	};

	const handleOnChangeInput =
		(props: keyof UserModel) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setUser({ ...user, [props]: event.target.value });
			if (props === "firstName") {
				if (event.target.value.length < 3) {
					setError({
						...error,
						firstName: "Votre nom doit contenir au moins 3 charactères.",
					});
					setCheckValidate({ ...checkIfValidate, firstName: false });
				} else {
					setError({
						...error,
						firstName: "",
					});
					setCheckValidate({ ...checkIfValidate, firstName: true });
				}
				checkIfEveryThingIsOk();
			}

			if (props === "lastName") {
				if (event.target.value.length < 3) {
					setError({
						...error,
						lastName: "Votre Prénom doit contenir au moins 3 charactères.",
					});
					setCheckValidate({ ...checkIfValidate, lastName: false });
				} else {
					setError({
						...error,
						lastName: "",
					});
					setCheckValidate({ ...checkIfValidate, lastName: true });
				}
				checkIfEveryThingIsOk();
			}
			if (props === "confirmPassword") {
				if (event.target.value !== user.password) {
					setError({
						...error,
						confirmPassword: "Vos mots de passe ne correspondent pas.",
					});
					setCheckValidate({ ...checkIfValidate, confirmPassword: false });
				} else {
					setError({
						...error,
						confirmPassword: "",
					});
					setCheckValidate({ ...checkIfValidate, confirmPassword: true });
				}
				checkIfEveryThingIsOk();
			}
			if (props === "password") {
				if (event.target.value.length < 6) {
					setError({
						...error,
						password: "Votre mot de passe doit dépasser 6 charactères.",
					});
					setCheckValidate({ ...checkIfValidate, password: false });
				} else {
					setError({
						...error,
						password: "",
					});
					setCheckValidate({ ...checkIfValidate, password: true });
				}
				checkIfEveryThingIsOk();
			}

			if (props === "perNum") {
				if (
					(event.target.value.length >= 2 || event.target.value.length <= 10) &&
					RegexValidationUtils.validPerNumNumber.test(event.target.value)
				) {
					setError({
						...error,
						perNum: "",
					});
					setCheckValidate({ ...checkIfValidate, perNum: true });
				} else {
					setError({
						...error,
						perNum:
							"Votre perNum doit être des chiffres compris de 2 à 10 charactères",
					});
					setCheckValidate({ ...checkIfValidate, perNum: false });
				}
				checkIfEveryThingIsOk();
			}
			if (props === "email") {
				if (
					!RegexValidationUtils.validEmail.test(
						String(event.target.value).toLowerCase(),
					)
				) {
					setError({
						...error,
						email: "Votre Address mail n'est pas valid.",
					});
					setCheckValidate({ ...checkIfValidate, email: false });
				} else {
					setError({
						...error,
						email: "",
					});
					setCheckValidate({ ...checkIfValidate, email: true });
				}
				checkIfEveryThingIsOk();
			}

			if (props === "phoneNumber") {
				if (
					(event.target.value.length >= 15 ||
						event.target.value.length <= 10) &&
					(!RegexValidationUtils.validPhoneNumber(event.target.value) ||
						!RegexValidationUtils.validPerNumNumber.test(event.target.value))
				) {
					setError({
						...error,
						phoneNumber:
							"Le format du numero est incorect un ex serait: +22792101032 ou 0022792101032.",
					});
					setCheckValidate({ ...checkIfValidate, phoneNumber: false });
				} else {
					setError({
						...error,
						phoneNumber: "",
					});
					setCheckValidate({ ...checkIfValidate, phoneNumber: true });
				}
				checkIfEveryThingIsOk();
			}
		};

	const checkIfEveryThingIsOk = (): boolean => {
		if (
			checkIfValidate.firstName &&
			checkIfValidate.lastName &&
			checkIfValidate.password &&
			checkIfValidate.confirmPassword &&
			checkIfValidate.perNum &&
			checkIfValidate.country &&
			checkIfValidate.email &&
			checkIfValidate.phoneNumber &&
			checkIfValidate.checkTermeOfUse
		)
			return true;
		else return false;
	};
	const saveDataToFirestore = async (
		event: React.FormEvent<HTMLFormElement>,
	) => {
		event.preventDefault();
		signOut(user);
	};
	const closeTermeOfUse = () => {
		setTermeOfUse(false);
	};
	const openTermeOfUse = () => {
		setTermeOfUse(true);
	};
	return (
		<>
			<SEO
				siteName={"fasoenchere"}
				title={`Connecter vous ou créer votre compte aujourd'hui`}
				url={"https://fasoenchere.com/register"}
				type={"website"}
				locale={"fr_FR"}
				description={`La société Smart chain International est une société de droit
					burkinabè. Elle fait la promotion de Unicorn Network qui est une
					entreprise communautaire mondiale`}
			/>
			<Container className={classes.root} maxWidth="xs">
				<Grid container alignContent="center" alignItems="center">
					<Typography className={classes.title}>
						Ouvrir votre compte avec les informations suivantes
					</Typography>
					<SizedBoxHeight height={24} />
					<form
						style={{ width: "100%" }}
						onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
							saveDataToFirestore(event)
						}>
						<FormControlWidget
							label="Prénom"
							type="text"
							value={user.firstName}
							onChange={handleOnChangeInput("firstName")}
							id="userFirstNale"
							Icon={Person}
							htmlFor="firstName of User"
						/>
						<p className={classes.error}>{error.firstName}</p>
						<FormControlWidget
							label="Nom"
							type="text"
							value={user.lastName}
							onChange={handleOnChangeInput("lastName")}
							id="userLastName"
							Icon={Person}
							htmlFor="lastName of User"
						/>
						<p className={classes.error}>{error.lastName}</p>
						<FormControlWidget
							label="PerNum"
							type="text"
							value={user.perNum}
							onChange={handleOnChangeInput("perNum")}
							htmlFor="perNum of User"
							id="userPernum"
							Icon={PermIdentity}
						/>
						<p className={classes.error}>{error.perNum}</p>
						<FormControlWidget
							label="email"
							type="text"
							value={user.email}
							onChange={handleOnChangeInput("email")}
							id="userEmail"
							Icon={Email}
							htmlFor="email of User"
						/>
						<Typography
							style={{ color: "grey" }}
							variant="subtitle1"
							align="left">
							Pays de résidence
						</Typography>
						<ReactFlagsSelect
							selected={selected}
							onSelect={(value) => {
								setSelected(value);
								changeHandlerCountry(value);
							}}
							searchable={searchable}
						/>
						{/* <Typography style={{color: 'grey'}} variant='subtitle1' align='left'>
              Pays de résidence
          </Typography>
          <Select options={options} value={user.country} onChange={changeHandlerCountry} /> */}
						<p className={classes.error}>{error.country}</p>
						<FormControlWidget
							label="Numéro de téléphone"
							type="text"
							value={user.phoneNumber}
							onChange={handleOnChangeInput("phoneNumber")}
							id="userPhoneNumber"
							Icon={Phone}
							placeholder="0022692666181"
							htmlFor="phone of User"
						/>
						<p className={classes.error}>{error.phoneNumber}</p>
						<FormControlWidget
							label="Mot de passe"
							type={user.showPassword ? "text" : "password"}
							value={user.password}
							onChange={handleOnChangeInput("password")}
							id="userPassword"
							Icon={Lock}
							htmlFor="password of User"
						/>
						<p className={classes.error}>{error.password}</p>
						<FormControlWidget
							label="Confirmation du mot de passe"
							type={user.showPassword ? "text" : "password"}
							value={user.confirmPassword}
							onChange={handleOnChangeInput("confirmPassword")}
							id="userConfirmPassword"
							Icon={Lock}
							htmlFor="confirm password of User"
						/>
						<p className={classes.error}>{error.confirmPassword}</p>
						<SizedBoxHeight height={14} />
						<Typography
							color="secondary"
							onClick={openTermeOfUse}
							className={classes.termeOfUse}>
							Voir les conditions d'utilisation.
						</Typography>
						<FormControlLabel
							control={
								<Checkbox
									checked={terme}
									onChange={handleCheckBoxChange}
									name="termsofuse"
								/>
							}
							label="J'accepte les conditions d'utilisation."
						/>
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							disabled={!checkIfEveryThingIsOk()}
							fullWidth>
							Soumettre
						</Button>
						<SizedBoxHeight height={14} />
					</form>
				</Grid>
				<Modal
					open={termeOfUse}
					onClose={closeTermeOfUse}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description">
					<Container
						maxWidth="sm"
						style={{
							backgroundColor: "#fff",
							overflowY: "scroll",
							height: "80%",
							marginTop: "50px",
						}}>
						<TermeOfUse />
						<div style={{ display: "flex", margin: "50px 0px" }}>
							<Button
								onClick={() => {
									closeTermeOfUse();
								}}
								color="secondary">
								Fermer
							</Button>
						</div>
					</Container>
				</Modal>
			</Container>
		</>
	);
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		signOut: (creds: UserModel) => dispatch(signUp(creds)),
	};
};

const mapStateToProps = (state: any) => {
	const uid = state.firebase.auth.uid;
	return {
		uid,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
