import { Dispatch } from "react";
import { Price } from "../../Models/PriceModel";

export const getProducts = () => {
  return (dispatch: Dispatch<any>, getState: any, { getFirestore }) => {
    const firestore = getFirestore();

    // get all the the products inside our databases
    firestore
      .collection("products")
      .get()
      .then((products: any) => {
        dispatch({
          type: "GET_PRODUCTS",
          products,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: "GET_PRODUCTS_ERR",
          error,
        });
      });
  };
};

export const addNewPrice = (
  userId: string,
  productId: string,
  prices: Price[],
  newPrice: string
) => {
  return (
    dispatch: Dispatch<any>,
    getState: any,
    { getFirebase, getFirestore }
  ) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;
    let allPrices: Price[];
    if (user) {
      firestore
        .collection("users")
        .doc(user.uid)
        .get()
        .then(async (snapshot: any) => {
          allPrices = [
            ...prices,
            {
              idUser: user.uid,
              phoneNumber: snapshot.data()?.phoneNumber,
              firstName: snapshot.data()?.firstName,
              lastName: snapshot.data()?.lastName,
              perNum: snapshot.data()?.perNum,
              country: snapshot.data().country || {
                label: "Burkina Faso",
                value: "BF",
              },
              price: parseInt(newPrice, 10),
            },
          ];
          // console.log("Affiche toi", allPrices);
        })
        .then(async () => {
          await firebase
            .firestore()
            .collection("products")
            .doc(productId)
            .update({
              prices: allPrices,
            });
        })
        .then((products: any) => {
          // console.log("from action creator", products);
          dispatch({ type: "ADD_NEW_PRICE", products });
        })
        .catch((err: any) => dispatch({ type: "ADD_NEW_PRICE_ERR", err }));
    }
  };
};
