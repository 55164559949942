import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
/* import firebase from '../firebaseConfig';
 */

const useStyles = makeStyles({
  root: {
    marginTop: "100px",
  },
});
function WaitingPage(props: any) {
  const classes = useStyles();
  const { isEmailVerify, uid } = props;
  if (isEmailVerify && uid) return <Redirect to="/" />;

  /*   const sendEmailVerficationSMS = (email: string) => {
    firebase.auth().currentUser?.sendEmailVerification();
  } */
  return (
    <Container className={classes.root}>
      <Typography>
        Veuillez consulter votre boite mail pour confirmer votre mail avant de
        poursuivre Une fois terminer veuillez rafraichir la page pour continuer.
      </Typography>
    </Container>
  );
}

const mapStateToProps = (state: any) => {
  const isEmailVerify = state.firebase.auth.emailVerified;
  const uid = state.firebase.auth.uid;
  console.log(state.firebase.auth);
  return {
    isEmailVerify,
    uid,
  };
};

export default connect(mapStateToProps)(WaitingPage);
