import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./redux/reducers/rootReducer";
import { Provider, useSelector } from "react-redux";
import thunk from "redux-thunk";
import firebase from "./firebaseConfig";
import { createFirestoreInstance } from "redux-firestore";
import { getFirestore } from "redux-firestore";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getFirebase,
  ReactReduxFirebaseProvider,
  isLoaded,
} from "react-redux-firebase";
import { CircularProgress, Container, Typography } from "@material-ui/core";
import { SizedBoxWidth } from "./Widgets/SizedBox";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))
  )
);

const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch,
  createFirestoreInstance,
};

function AuthIsLoaded(props: any) {
  const auth = useSelector((state: any) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <Container
        maxWidth="sm"
        style={{
          marginTop: "100px",
          width: "100%",
          position: "absolute",
          left: "50%",
          right: "50%",
          top: "30%",
          bottom: "50%",
        }}
      >
        <CircularProgress />
        <SizedBoxWidth width={20} />
        <Typography variant="body2">Loading...</Typography>
      </Container>
    );
  return props.children;
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <React.StrictMode>
        <AuthIsLoaded>
          <App />
          <ToastContainer />
        </AuthIsLoaded>
      </React.StrictMode>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
