import UserConnexionModel from "../../Models/UserConnexion";
import { toast } from "react-toastify";
import UserModel from "../../Models/UserModel";


export const signIn = (creds: UserConnexionModel) => {
  return (dispatch: any, getState: any, { getFirebase }: any) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then((result: any) => {
        // Let's get the state of the user and make him stay logged in even if he closes the browser of refreshes the page
        if (result.user.emailVerified === false)
          result.user.sendEmailVerification();
        if (firebase.auth().currentUser) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((token: any) => {
              dispatch(signInSuccess(creds));
              toast.success(
                "Rebienvenu Chère utilisateur",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            });
        } else {
          dispatch(signInError("No user found"));
        }
      })
      .catch((error: any) => {
        if(error.message === 'The password is invalid or the user does not have a password.')
        {
      toast.error(
          'The credentials are invalid',
          {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        }
     if(error.message !== 'The password is invalid or the user does not have a password.') { 
      toast.error(
        error.message,
        {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
        dispatch(signInError(error));
      });
  };
};

export const signInSuccess = (creds: UserConnexionModel) => {
  return {
    type: "SIGN_IN_SUCCESS",
    creds,
  };
};

export const signInError = (error: any) => {
  return {
    type: "SIGN_IN_ERROR",
    error,
  };
};

export const signOut = () => {
  return (dispatch: any, getState: any, { getFirebase }: any) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGN_OUT" });
      });
  };
};

export const resetPassword = (email: string) => {
  return (dispatch: any, getState: any, { getFirebase }: any) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success(
          "Mail Envoyé. Consulter votre boite mail pour continuer le processus de rénitialisation.",
          {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch({ type: "RESET_PASSWORD" });
      }).catch((err) => {
        toast.error(
          err.message,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };
};

export const signUp = (creds: UserModel) => {
  return (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .createUserWithEmailAndPassword(creds.email, creds.password)
      .then((result: any) => {
        if (result.user) {
          firestore
            .collection("users")
            .doc(result.user.uid)
            .set({
              uid: result.user.uid,
              email: creds.email,
              firstName: creds.firstName,
              lastName: creds.lastName,
              country: creds.country ?? {},
              phoneNumber: creds.phoneNumber,
              perNum: creds.perNum,
            })
            .then(() => {
              toast.success(
                'Votre compte à été créer avec success',
                {
                  position: "top-right",
                  autoClose: 3500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
              result.user.sendEmailVerification().then(() => {
                toast.success(
                  'Veullez consulter votre boite mail pour confirmer votre address mail',
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                dispatch({ type: "SIGN_UP" });
              });
            });
        }
      })
      .catch((err: any) => {
        toast.error(
          err.message,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch({ type: "SIGN_UP_ERR" }, err);
      });
  };
};


