import {
  AppBar,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SizedBoxWidth } from "../Widgets/SizedBox";
import { signOut } from "../redux/actions/authAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    navLink: {
      textDecoration: "none",
      fontSize: 24,
      width: '200px',
      color: "#fff",
    },
    loginAndRegisterDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "right",
      marginLeft: "auto",
    },
    appNavLink: {
      display: "flex",
      flex: 0.7,
      alignItems: "center",
    },
    nav: {
      flex: 0.7,
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      appTitle: {
        fontSize: 10,
        fontWeight: "bold",
        color: "#fff",
        textDecoration: "none",
      },
      toolbar: {
        width: "fit-content",
      },
      appNavBarLink: {
        fontSize: 12,
        width: 'fit-content',
        maxWidth: "200px",
        fontWeight: "bold",
      },
      nav: {
        flex: 0.5,
        width: "fit-content",
      },
      navLink: {
        textDecoration: "none",
        fontSize: 24,
        width: '100px',
        color: "#fff",
      },
    },
    appTitle: {
      fontSize: 20,
      fontWeight: "bold",
      color: "#fff",
      textDecoration: "none",
    },
    appNavBarLink: {
      fontSize: 12,
      width: 'fit-content',
      maxWidth: "200px",
      fontWeight: "bold",
    },
  })
);

function NavbarComp(props: any) {
  // console.log(props);

  const classes = useStyles();
  return (
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <Link to="/" className={classes.appTitle}>
          <Typography variant="body2" className={classes.appTitle}>
            Faso Enchère
          </Typography>
        </Link>
        <div className={classes.nav}>
          {props.uid ? (
            <div className={classes.loginAndRegisterDiv}>
              <div className={classes.appNavLink}>
                &nbsp;
                <Link to="/about_us" className={classes.navLink}>
                  <Typography
                    variant="subtitle1"
                    className={classes.appNavBarLink}
                  >
                    Qui Sommes Nous
                  </Typography>
                </Link>
              </div>
              &nbsp;
              <Button className={classes.navLink} onClick={props.signOut}>
                <Typography>SignOut</Typography>
              </Button>
            </div>
          ) : (
            <div className={classes.loginAndRegisterDiv}>
              <SizedBoxWidth width={10} />
              <Link to="/login" className={classes.navLink}>
                {/* // Let's put it at the very right of the navbar */}
                <Typography>Login</Typography>
              </Link>
              <SizedBoxWidth width={1} />
              <Link to="/register" className={classes.navLink}>
                <Typography>Register</Typography>
              </Link>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};
const mapStateToProps = (state: any) => {
  const uid = state.firebase.auth.uid;
  return {
    uid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComp);
